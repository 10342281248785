import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Navbar:{
        Home: "الرئيسية",
        Chisiamo:"معلومات عننا",
        Servizi:"الخدمات",
        Destinazioni:"الرحلات",
        Proposte:"العروض",
        Contatti:"تواصل معنا",
        Lingua:"اللغة"
    },
    Carousel:{
        Benvenuti :"مرحبا بك",
        Hobitours :"هوبيتورز"
    },
    About:{
        Chisiamo :"معلومات عننا",
        p1:"عشاق السفر وموظفي السياحة وبالطبع عشاق مصر!",
        p2:"لقد جعلنا شغفنا، وظيفتك.",
        p3:"نحن نحب أن نحلم والسفر من أجمل الأحلام... نريد مساعدتك لتحقيق حلمك وتنظيم رحلتك بأفضل طريقة ممكنة وجعلك تعيش أفضل تجربة في هذه الأرض ذات التناقضات القوية بين التقاليد والحداثة، حيث ترحب بكم الابتسامة ويضمن لكم المرح.",
        p4:"مصر بلد المشاعر القوية التي ستبقى في قلوبكم إلى الأبد!"
    }
    ,
    offersTitle:{
        title:'استكشف عروضنا',
        p1:"لمدة",
        p2:"ايام & ",
        p3:"ليلة ",
        p4:"ليالى "
    },
    services:{
        title:"الخدمات",
        p1:"الانتقالات الي المطار",
        p2:"و من المطار",
        p3:"الفنادق",
        p4:"والمنتجعات",
        p5:"الرحلات",
        p6:"رحلات نيلية",
        p7:"سفاري",
        p8:"استرحات المدينة"
    },
    contactTitle:"تواصل معنا",
    contactCard:{
        p1:"وسائل التواصل",
        p2:"الرجاء التواصل معنا. يسعدنا تلقي استفساراتك.",
        p3:"إيميل:",
        p4:"هاتف:",
        p5:"واتساب:",
        p6:"فيس بوك:",
        p7:"إنستجرام:"
    },
    contactForm:{
        p1:"الإيميل",
        p2:"الاسم",
        p3:"الموضوع",
        p4:"الرسالة",
        p5:"إرسال"
    },
    info:"للاستفسارات والحجز يمكنكم التواصل معنا من خلال :",
    offer:{
        p1:"لمدة",
        p2:"ايام & ",
        p3:"ليلة ",
        p4:"ليالى ",
        p5:"تفاصيل العرض :",
        p6:"الصور غير موجودة !"
    },
    destination:{
        p1:"تفاصيل الرحلة :",
        p2:"لا يجب ان تفوت :",
        p3:"الصور غير موجودة !",
        p4:"رؤية فنادقنا هناك"
    },
    hotel:{
        p1:"تفاصيل الفندق :",
        p2:"العودة الي الرحلة",
        p3:"الصور غير موجودة !",
        p4:"ليس لدينا فنادق هناك بعد !"
    }
}

export const arSlice = createSlice({
    name : 'ar',
    initialState,
    reducers:{
    }
})


export default arSlice.reducer